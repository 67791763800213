import Vue from "vue";

/*TITLE*/
document.title = "Terrazas de Jerez | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Terrazas de Jerez";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Terrazas de Jerez";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-bottega-caliza--20230126050132.jpg";
Vue.prototype.$image_kitchen = "cocina-bottega-caliza-443x443-rivoli-333x592-blanco-nubol-xtone-bottega-caliza--20230126050103.jpg";
Vue.prototype.$image_bath1 = "banop_ducha-bottega-caliza-443x443-bottega-caliza-333x592-spiga-bottega-caliza-333x592--20230126050155.jpg";
Vue.prototype.$image_bath2 = "banos-bottega-caliza-443x443-bottega-caliza-333x592-spiga-bottega-caliza-333x592--20230126050125.jpg";
Vue.prototype.$image_room = "dormitorio-bottega-caliza--20230126050147.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-bottega-caliza--20230126050132.jpg",
  },
  {
    src: "salon-ac4-residence-1l-nevada--20230126050142.jpg",
  },
  {
    src: "salon-ac4-residence-1l-arizona--20230126050149.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-bottega-caliza-443x443-rivoli-333x592-blanco-nubol-xtone-bottega-caliza--20230126050103.jpg",
  },
  {
    src: "cocina-bottega-acero-443x443-rivoli-333x592-etimoe-ice-frame-white--20230126050110.jpg",
  },
  {
    src: "cocina-bottega-acero-443x443-china-blanco-333x592-zinc-xtone-aged-dark--20230126050115.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop_ducha-bottega-caliza-443x443-bottega-caliza-333x592-spiga-bottega-caliza-333x592--20230126050155.jpg",
  },
  {
    src: "banop-bottega-caliza-443x443-bottega-caliza-333x592-spiga-bottega-caliza-333x592--20230126050105.jpg",
  },
  {
    src: "banop-bottega-caliza-443x443-bottega-caliza-333x592-marbella-stone-333x592--20230126050111.jpg",
  },
  {
    src: "banop-bottega-acero-443x443-bottega-acero-333x592-spiga-bottega-acero-333x592--20230126050118.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banos-bottega-caliza-443x443-bottega-caliza-333x592-spiga-bottega-caliza-333x592--20230126050125.jpg",
  },
  {
    src: "banos-bottega-caliza-443x443-bottega-caliza-333x592-marbella-stone-333x592--20230126050131.jpg",
  },
  {
    src: "banos-bottega-acero-443x443-bottega-acero-333x592-spiga-bottega-acero-333x592--20230126050138.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "dormitorio-bottega-caliza--20230126050147.jpg",
  },
  {
    src: "dormitorio-ac4-residence-1l-nevada--20230126050154.jpg",
  },
  {
    src: "dormitorio-ac4-residence-1l-arizona--20230126050100.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/TERRAZAS_DE_JEREZ/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/805573808?h=c76385a8d3";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20josé%20ignacio%20pineda%20manzana%20m1%2011405%20jerez%20cádiz%20",
    text: "Calle José Ignacio Pineda, Manzana M1. 11405 Jerez (Cádiz)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=oficina%20city10%20en%20calle%20sevilla%2047%20bajo%2011402%20jerez%20cádiz%20",
    text: "OFICINA CITY10 en Calle Sevilla, 47, bajo. 11402 Jerez (Cádiz)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:956350003",
    text: "956350003",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@terrazasdejerez.com",
    text: "info@terrazasdejerez.com",
  },
];
